import {useEffect, useState} from "react";
import {Dispatch} from "@reduxjs/toolkit";
import {useDispatch, useSelector} from "react-redux";

import PageLayout from "../../../components/layouts/PageLayout";
import StatCard from "../../../components/cards/StatCard";
import AreaCharts from "../../../components/charts/AreaCharts";
import PieCharts from "../../../components/charts/PieCharts";
import LineCharts from "../../../components/charts/LineCharts";
import StatTableCard from "../../../components/cards/StatTableCard";
import HalfPieCharts from "../../../components/charts/HalfPieCharts";
import SijuInsights from "../saas/misc/SijuInsights";
import FilterInsightsModal from "../../../components/modals/FilterInsightsModal";
import {dispatchInsights} from "../../../store/slices/insights.slice";
import {
  formatAdvInsights,
  formatCommissionChart,
  formatCommissionSummary,
  formatHealthChart,
  formatRevInsights,
  formatTripsInsights,
  formatWithdrawalInsights,
  formatWithdrawalStatus
} from "../../../utils/helpers/logicHelper";
import {MenuType} from "../../../utils/helpers/constants";


const Home = () => {
  const yMap: number = 40000000;
  const dispatch: Dispatch = useDispatch(), date: Date = new Date(), year: string = date.getFullYear().toString();
  const loadedOverview: boolean = useSelector((state: IStoreState) => state.insights.loadedOverview)
  const overview: any = useSelector((state: IStoreState) => state.insights.overview)
  const finInsights: any = useSelector((state: IStoreState) => state.insights.finInsights)
  const [filterRender, setFilterRender] = useState<boolean>(false)

  useEffect(() => {
    if (!loadedOverview) getOverview().catch(err => console.log(err))
  }, []);

  const getOverview = async (): Promise<void> => await dispatchInsights(dispatch, {year})
  const filterPayload = async (payload: IFilterPayload): Promise<void> => await dispatchInsights(dispatch, payload);

  let revYKey: IYKey[] = [
    {name: 'GTV', key: 'gross', stroke: "#8884d8"}, {name: 'Net', key: 'net', stroke: "#82ca9d"},
    {name: 'Commission', key: 'commission', stroke: "#18b32a"}
  ]
  let healthYKey: IYKey[] = [
    {name: 'Balance', key: 'props', stroke: "#8884d8"}, {name: 'Commission', key: 'commission', stroke: "#82ca9d"},
  ]
  let advYKey: IYKey[] = [
    {name: 'Volume', key: 'volume', stroke: "#8884d8"}, {name: 'Commission', key: 'commission', stroke: "#18b32a"}
  ]
  let withYKey: IYKey[] = [
    {name: 'Successful', key: 'successful', stroke: "#18b32a"}, {name: 'Failed', key: 'failed', stroke: "#7e0839"}
  ]

  const filterModal = filterRender &&
		<FilterInsightsModal onchange={() => setFilterRender(false)} setFilterPayload={filterPayload}/>

  return (
    <PageLayout active={MenuType.HOME} title="Siju by truQ - Insights" loading={!loadedOverview}>
      {filterModal}
      <div className="container-fluid">
        <div className="d-flex flex-wrap justify-content-end gap-2 mb-3">
          <button className="btn btn-primary pointer-event" onClick={() => setFilterRender(true)}>
            <i className="bx bx-filter me-1"></i> Filter
          </button>
        </div>

        <SijuInsights overview={overview}/>
        <div className="row">
          <div className="col-lg-6">
            <AreaCharts name={`Total Gross Revenue - ${finInsights.type}`} yDomain={[0, yMap]}
                        data={formatRevInsights(finInsights.transactions)} yKey={revYKey}/>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6 ">
                <StatCard icon="bx-money" text="Gross Transaction Volume"
                          description={`Gross Revenue - ${finInsights.type}`}
                          value={`₦${formatTripsInsights(finInsights.transactions.gross)}`}/>
              </div>
              <div className="col-sm-6">
                <StatCard icon="bx-card" text="Tenants Transaction Revenue"
                          description={`Net Revenue accrued by all tenants - ${finInsights.type}`}
                          value={`₦${formatTripsInsights(finInsights.transactions.net)}`}/>
              </div>
              <div className="col-sm-6">
                <StatCard icon="bx-transfer" text="Accrued Transactions VAT"
                          description={`VAT accrued on all transactions - ${finInsights.type}`}
                          value={`₦${formatTripsInsights(finInsights.transactions.vat)}`}/>
              </div>
              <div className="col-sm-6">
                <StatCard icon="bxs-map-pin" text="Number of transactions"
                          description={`Completed Trips Transactions - ${finInsights.type}`}
                          value={formatTripsInsights(finInsights.transactions.trip)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <LineCharts name={`Siju Health Information - ${finInsights.type}`} yDomain={["dataMin", yMap]}
                        yKey={healthYKey} data={formatHealthChart(finInsights)}/>
          </div>
          <div className="col-lg-4">
            <StatTableCard text={`Siju Commission - ${finInsights.type}`} data={formatCommissionSummary(finInsights)}/>
          </div>
          <div className="col-lg-4">
            <PieCharts name={`Transactions Commission - ${finInsights.type}`}
                       data={formatCommissionChart(finInsights)}/>
          </div>

        </div>
        <div className="row">
          <div className="col-lg-8">
            <AreaCharts name={`Withdrawal Transactions Volume - ${finInsights.type}`} yDomain={[0, yMap]}
                        data={formatWithdrawalInsights(finInsights.withdrawals)} yKey={withYKey}/>
          </div>
          <div className="col-lg-4">
            <HalfPieCharts name={`Withdrawal Transactions Status - ${finInsights.type}`}
                           data={formatWithdrawalStatus(finInsights.withdrawals)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <AreaCharts name={`Advanced Payment Volume - ${finInsights.type}`} yDomain={[0, yMap]}
                        data={formatAdvInsights(finInsights.advanced_payments)} yKey={advYKey}/>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6">
                <StatCard icon="bx-card" text="Advanced Payments Volume"
                          description={`Advanced Payments Processed - ${finInsights.type}`}
                          value={`₦${formatTripsInsights(finInsights.advanced_payments.volume)}`}/>
              </div>
              <div className="col-sm-6">
                <StatCard icon="bx-credit-card" text="Advanced Payments Commission"
                          description={`Advanced Payments Commission - ${finInsights.type}`}
                          value={`₦${formatTripsInsights(finInsights.advanced_payments.commission)}`}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default Home;
